<template>
  <ClientOnly>
    <v-navigation-drawer
      v-if="!mdAndUp"
      v-show="isMainMenuOpen && !mdAndUp"
      v-model="isMainMenuOpen"
      class="mt-0 position-fixed mb-12 main-menu-mobile"
      location="left"
      width="482"
      height="300"
      temporary
      :scrim="smAndDown ? false : 'white'">
      <v-container class="position-fixed test bg-white pa-0">
        <v-container class="bg-surface-darken pt-0 pb-6 sidebar-content">
          <v-row class="px-0">
            <v-col cols="12" class="py-4 px-4">
              <div class="font-weight-bold text-center headline">
                {{ t('mainmenu_title_mobile') }}
              </div>
              <v-row class="mt-2">
                <v-col v-for="(card, index) of fractionsSorted" :key="index" cols="4" class="pa-1">
                  <CdeCard min-width="40" class="d-flex flex-column" @click="goToDestination(card)">
                    <v-card-item class="justify-center mb-0 pb-0">
                      <NuxtImg
                        provider="directus"
                        :src="card.image || ''"
                        :modifiers="{ format: 'auto', width: 60, height: 60 }"
                        height="60"
                        :alt="card.description"
                        width="60"
                        placeholder />
                    </v-card-item>
                    <v-card-item class="justify-center px-1 pt-0">
                      <v-card-subtitle
                        class="text-center font-weight-bold opacity-100 card-title align-content-center">
                        {{ card.description }}
                      </v-card-subtitle>
                    </v-card-item>
                  </CdeCard>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="text-secondaryText text-block pl-6">
              <p class="font-weight-bold">{{ t('mainmenu_text_1') }}</p>
              <p class="small">{{ t('mainmenu_text_2') }}</p>
              <nuxt-link
                :href="'tel:' + phone.replace(/\s/g, '')"
                class="text-secondaryText text-decoration-none font-weight-bold small">
                <v-icon>mdi-phone</v-icon>
                {{ phone }}
              </nuxt-link>
              <button-secondary class="b2b-button" :to="b2blink" @click="closeMainMenu">
                {{ t('mainmenu_button_label') }}
              </button-secondary>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-navigation-drawer>

    <!-- Desktop -->
    <v-navigation-drawer
      v-if="mdAndUp"
      v-show="isMainMenuOpen && mdAndUp"
      v-model="isMainMenuOpen"
      location="top"
      temporary
      position-relative
      class="main-menu-desktop bg-surface-darken border-t pb-6">
      <v-container class="pb-6">
        <v-row class="px-0">
          <v-col cols="12" class="py-4 px-4">
            <div class="font-weight-bold headline pl-3">
              {{ t('mainmenu_title_desktop') }}
            </div>
            <v-row class="mt-2">
              <v-col
                v-for="(card, index) of fractionsSorted"
                :key="index"
                cols="4"
                lg="3"
                xl="2"
                class="">
                <CdeCard min-width="40" class="d-flex" elevation="0" @click="goToDestination(card)">
                  <v-card-item class="pr-0">
                    <NuxtImg
                      provider="directus"
                      :src="card.image || ''"
                      :modifiers="{ format: 'auto', width: 60, height: 60 }"
                      :alt="card.description"
                      height="60"
                      width="60"
                      placeholder />
                  </v-card-item>
                  <v-card-item class="">
                    <v-card-subtitle class="font-weight-bold opacity-100">
                      <p>{{ card.description }}</p>
                      <div class="font-weight-thin card-allowed-items">
                        z.B.
                        {{ card.allowed_items_menu?.slice(0, 3).join(', ').substring(0, 30) }} ...
                      </div>
                    </v-card-subtitle>
                  </v-card-item>
                </CdeCard>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="text-secondaryText text-block text-right pr-7"
            :class="lgAndUp || xlAndUp ? 'move-up' : ''">
            <p class="font-weight-bold">{{ t('mainmenu_text_1') }}</p>
            <p class="small">{{ t('mainmenu_text_2') }}</p>
            <nuxt-link
              :href="'tel:' + phone.replace(/\s/g, '')"
              class="text-secondaryText text-decoration-none font-weight-bold small">
              <v-icon>mdi-phone</v-icon>
              {{ phone }}
            </nuxt-link>
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>
  </ClientOnly>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify'
import type { Fraction } from '~/types/types'

const { t } = useI18n()

defineProps<{
  phone: string
  b2blink?: string
}>()

const { smAndDown, mdAndUp, lgAndUp, xlAndUp } = useDisplay()
const sidebarStore = useSidebarStore()
const { isMainMenuOpen } = storeToRefs(sidebarStore)
const closeMainMenu = () => sidebarStore.closeMainMenu()
const fractionStore = useFractionStore()
const offerStore = useOfferStore()
fractionStore.getFractionData()
const { fractionsSorted } = storeToRefs(fractionStore)

const goToDestination = async (card: Fraction) => {
  offerStore.setCurrentFractionId(card.id)
  await navigateTo('/' + card.shop_slug)
}
</script>

<style scoped lang="scss">
.main-menu-desktop {
  top: 120px !important;
  max-height: calc(100vh - 100px) !important;
}
.mdi-close {
  transform: scale(1.5);
}

.mdi-phone {
  transform: scale(0.7);
}

.headline {
  font-size: 1.05rem;
}

.b2b-button {
  margin-top: 10px;
  height: 60px;
  min-width: 70%;
  font-size: 14px;
}

.card-title {
  height: 38.19px;
  font-size: 0.75rem;
  white-space: wrap;
  word-break: break-word;
  text-overflow: ellipsis;
}

.v-card-subtitle {
  p {
    hyphens: auto;
    width: 160px;
    height: 40px;
    display: block;
    white-space: break-spaces;
  }
}
.card-allowed-items {
  font-size: 0.75rem;
  white-space: wrap;
  width: 75%;
}

.main-menu-mobile {
  top: 55px;
  height: calc(100vh - 48px) !important;
  position: fixed;
  .sidebar-content {
    overflow: scroll;
    height: calc(100vh - 48px);
  }
}

.text-block {
  line-height: 1.2rem;
  .small {
    font-size: 0.8rem;
  }
  &.move-up {
    margin-top: -100px;
  }
}
</style>
